.catdog-bg {
    background: #feda9c;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-height: 100%;
    max-width: 450px;
    min-height: calc(var(--viewport-height) - 65px);
}
.catdog-bg-top {
    background-image: url(./images/bg.170892b4.png);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    aspect-ratio: 1.15;
    position: relative;
}
.catdog-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
}
.catdog-button {
    display: flex;
    gap: 10px;
}
.catdog-button-item {
    width: 35px;
    display: flex;
    cursor: pointer;
}
.catdog-money {
    background-image: url(./images/btn_balance_bg.b7585169.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 35px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    padding: 5px;
}
.catdog-money div {
    background: #210a49;
    border-radius: 10px;
    padding: 3px;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    width: 130px;
    color: #fff;
}
.catdog-bg-text {
    background-image: url(./images/title_bg.8c6bba61.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 40px;
    line-height: 38px;
    text-align: center;
    color: #fff;
    font-size: 0.35rem;
}
.catdog-logo {
    background-image: url(./images/logo.24e600c6.png);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 260px;
    height: 100px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 142px;
}
.catdog-choose {
    padding: 15px;
    display: flex;
    gap: 15px;
}
.catdog-choose > div {
    flex: 1;
    background-image: url(./images/2_nope.a8eca0e9.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    aspect-ratio: 0.75;
    position: relative;
    cursor: pointer;
}
.catdog-choose > .catdog-choose-cat {
    background-image: url(./images/1_nope.c9061fd2.png);
}
.catdog-choose > .catdog-choose-tie {
    background-image: url(./images/2_nope.a8eca0e9.png);
}
.catdog-choose > .catdog-choose-dog {
    background-image: url(./images/3_nope.ee1081da.png);
}
.catdog-choose > .catdog-choose-cat.active {
    background-image: url(./images/1_nope_light.6d7576bc.png);
}
.catdog-choose > .catdog-choose-tie.active {
    background-image: url(./images/2_nope_light.af34ac36.png);
}
.catdog-choose > .catdog-choose-dog.active {
    background-image: url(./images/3_nope_light.feb6c5a4.png);
}
.catdog-choose-rate {
    background: #94540d;
    color: #fff;
    padding: 3px 10px;
    border-radius: 0 0 10px 10px;
    color: #fff;
    font-size: 0.26rem;
    font-weight: bold;
    width: 50%;
    text-align: center;
    position: absolute;
    top: 9%;
    left: 50%;
    transform: translateX(-50%);
}
.catdog-choose-rate span {
    color: #f9c53c;
}
.catdog-choose-you {
    background: #fee2a1;
    width: calc(100% - 30px);
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #453407;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: .1em;
    padding: 20px 0;
    font-size: 0.32rem;
    height: 65%;
    gap: 1em;
}
.catdog-choose-you span {
    font-size: 0.45rem;
    font-weight: bold;
    color: #94540d;
}
.catdog-choose img {
    position: relative;
    bottom: -3px;
    margin-right: 5px;
}
.catdog-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    padding: 0 15px 15px;
}
.catdog-bottom img {
    max-height: 80%;
    width: auto;
}
.catdog-charge {
    background-image: url(./images/charge_btnbg.9f94f3c9.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
}
.catdog-form {
    background: #bc7a02;
    border-radius: 3px;
    height: 32px;
    width: 100%;
    margin: 0 1px 4px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3px;
}
.catdog-form input {
    background: none;
    border: none;
    outline: none;
    width: 60%;
    margin: auto;
    text-align: center;
    color: #fee2a1;
    font-weight: bold;
    font-size: 0.35rem;
}
.catdog-btnmax button {
    background: #fefcf0;
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 0.38rem;
    border: 3px solid #ae6116;
    border-bottom: 5px solid #ae6116;
    border-radius: 10px;
    cursor: pointer;
    color: #94540d;
}
.catdog-btnmax.active button {
    background-image: url(./images/btn_maxbg.5e7d8d4a.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    color: #94540d;
    border: 0;
}
.catdog-animation {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.catdog-animation canvas {
    width: 100%;
    height: 100%;
    aspect-ratio: 1.5;
}
.catdog-countdown canvas {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
.catdog-bangketqua {
    position: fixed;
    z-index: 999;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 450px;
    background: #ffedc0;
    border-radius: 30px;
    border: 3px solid #94540d;
    border-bottom: 6px solid #94540d;
    padding: 30px 15px 15px;
}
.catdog-ketqua {
    padding: 10px 0 0;
    position: relative;
    color: #985831;
    font-weight: bold;
    font-size: 0.45rem;
}
.catdog-bangketqua-close {
    position: absolute;
    top: -32px;
    right: -5px;
    height: 20px;
    width: 20px;
    font-size: 25px;
    color: #333;
    cursor: pointer;
}
.catdog-award {
    margin-top: -80px;
}
.catdog-award canvas {
    width: 60%;
    height: 100%;
    aspect-ratio: 3;
}
.catdog-award .nohu canvas {
    width: 80%;
    height: 100%;
    aspect-ratio: 3;
}
.catdog-bangketqua-dong {
    background-image: url(./images/btn-purple.7b0d2f47.png);
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: calc(100% - 40px);
    height: 1.2rem;
    margin: 20px 20px 0;
    color: #fff;
    font-size: 0.4rem;
    line-height: 1.1rem;
}
.tab-history-catdog {
    margin: 0;
}
.tab-history-catdog > li {
    background: #ffe8b2;
    color: #947936;
    border: 2px solid #ffe8b2;
}
.tab-history-catdog > li.active {
    background: #ffe8b2;
    border: 2px solid #725420;
    color: #725420;
    font-weight: bold;
}
.catdog-table {
    border: 1px solid #ffdd8e!important;
    border-radius: 10px;
    overflow: hidden;
}
.catdog-table thead {
    background: #ffdd8e;
    color: #d09849;
    font-weight: bold;
}
.catdog-table tbody tr {
    border: 0;
    background: #fff8e6!important;  
}
.catdog-table thead td {
    border: 1px solid #ffdd8e;
    text-align: center!important;
}
.catdog-table tbody td {
    border: 1px solid #ffdd8e;
    text-align: center!important;
    color: #baa06e!important;
}
.catdog-table tbody td .tick {
    width: 25px;
    height: 25px;
    background-image: url(./images/i3.png);
    background-size: 100% 100%;
    margin: auto;
}
.catdog-table-scroll {
    max-height: 10.3rem;
    overflow-y: auto;
}
.catdog-total {
    color: #9e8e77;
    font-size: 0.3rem;
    margin-top: 1rem;
    display: none;
}
.catdog-total span {
    font-size: 0.4rem;
    font-weight: bold;
    color: #94540d;
    display: block;
}
.catdog-submit {
    grid-column: 3/6;
    background-image: url(./images/btn-purple.7b0d2f47.png);
    background-size: 100% 100%;
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 0.38rem;
    border-radius: 10px;
    cursor: pointer;
    border: 0;
    color: #fff;
    background-color: transparent;
}
.catdog-reset {
    grid-column: 1/2;
    background: #c9c9c9;
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 0.38rem;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    color: #333;
}
@media (max-width: 420px) {
    .catdog-choose {
        gap: 5px;
    }
    .catdog-choose-you {
        padding: 5px 0;
        width: calc(100% - 20px);
        left: 10px;
        bottom: 10px;
    }
    .catdog-choose-rate {
        width: 60%;
    }
}