.footer {
    background: rgba(11, 40, 58);
    border-top: 1px solid rgb(24, 66, 88);
    padding: 0.15rem 0.1rem 0.1rem;
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    font-size: 12px;
    height: 50px;
}
.footer_item {
    width: 25%;
    flex: 1;
}
.icon_footer,
.footer img {
    height: 22px;
    margin-bottom: 2px;
}
.footer a {
    color: rgba(255, 255, 255, 0.6);
}
.footer .active a {
    color: #1bc870;
}
.icon_footer_center {
    align-items: center;
    background: linear-gradient(180deg, #f09c44, #1bc870);
    border-radius: 50%;
	top: -15px;
    display: flex;
    height: 42px;
    justify-content: center;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 42px;
}
.icon_footer_center img {
    filter: grayscale(100%) brightness(400%);
    font-size: 0;
    height: 30px;
    width: 30px;
}
