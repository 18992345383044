.info_profile {
	background-color: red;
	display: flex;
	justify-content: space-between;
	height: 43px;
	padding: 5px 10px;
}
.content-home {
	max-width: 869%;
	overflow: auto;
}
.carousel {
	height: 175px;
}
.title-speaker {
	color: red;
	float: left;
	display: flex;
	align-items: center;
}
.row-content-home {
	color: red;
}
.text-red {
	color: red;
	font-size: 12px;
}
.item_game {
	width: 100%;
	flex-shrink: 0;
	overflow: auto hidden;
}
.col-game {
	float: left;
	width: 100%;
	box-sizing: border-box;
	padding: 0px 10px;
}
.item-game1 {
	display: table;
	width: 100%;
}
.a-btn {
	padding: 3px 8px;
	background: red;
	border-radius: 5px;
	color: white;
	width: 100%;
	max-width: 100px;
	cursor: pointer;
}
.title-game {
	font-size: 15px;
	padding-right: 5px;
	text-align: left;
	padding-left: 10px;
	display: table-cell;
	word-break: break-word;
	color: red;
	vertical-align: middle;
}
.btn-join {
	display: table-cell;
	width: 80px;
	white-space: nowrap;
	cursor: pointer;
}
.img-games {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
.col-game-box {
	padding: 10px;
	border-width: 2px;
	border-style: solid;
	border-color: red;
	border-image: initial;
	margin: 5px;
	height: 70px;
	border-radius: 5px;
	background-color: transparent;
	text-align: center;
	margin: 0 0 20px;
	position: relative;
}
.content-nav {
	padding: 5px 20px;
	display: flex;
	justify-content: space-between;
	background: #fff;
	border-bottom: 1px solid #f4f4f4;
	width: calc(100% - 20px);
	border-radius: 50px;
	margin: 0 auto 15px;
	box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	border-bottom: 0px;
}
.notifiall {
	position: relative;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 10px;
	line-height: 26px;
	font-weight: 500;
	display: block;
	padding: 5px 16px;
	color: red;
}
.icon_setting_header {
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 25px;
	padding-bottom: 5px;
	text-decoration: none;
}
.icon_setting_header > * {
	margin-right: 5px;
}
.header_profile {
	width: 100%;
	background-image: linear-gradient(270deg, #52b7d2, #6271ac);
}
.avatar_profile {
	display: flex;
	align-items: center;
}
.avatar_profile img {
	max-height: 58px;
}
.detail_avatar {
	overflow: hidden;
	border-radius: 50px;
	width: 15%;
	height: 15%;
	padding: 20px;
}
.name_profile {
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 1.2rem;
}
.content_profile {
	position: relative;
}
.list-bank {
	padding: 0 10px;
}
.list-bank > .title {
	font-weight: 500;
}
.list-bank a {
	text-decoration: none;
}
.item-bank {
	display: flex;
}
.item-bank > div {
	border: 0.5px solid #ddd;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.item-bank > div:not(.edit) {
	width: 30%;
	text-align: left;
	padding: 5px;
}
.item-bank > .edit img {
	margin: 5px auto;
}
.item-bank > .edit {
	width: 10%;
	text-align: center;
}
:root {
	--color-main: #25cb83;
	--color-main-hover: #25cb83;
	--color-text: #333;
	--color-text-light: #fff;
	--color-link: #1d2327;
	--color-link-hover: #242f48;
	--color-link-content: #6422c3;
	--color-link-content-hover: #0d6efd;
	--color-line: rgba(234,239,244,.5);
	--color-note: #888c91;
	--color-section: #fff;
	--color-background: #f5f5f5;
	--box-shadow: 0px 4px 10px #626262;
	--border-input: 1px solid var(--color-line);
	--transition: 300ms all ease-in-out;
	--scrollbarBG: #e9ecef;
	--width-content: 800px;
	--bg-main: #25cb83;
	--bg-app: #fff;
}
.account {
	color: #fff;
	height: 100%;
	width: 100%;
	z-index: 2;
	position: relative;
	background: linear-gradient(to bottom, #0b2737, #081f2d);
	padding-bottom: 50px;
}
.account h1 {
	color: red;
	margin: 0.4rem 0 !important;
}
.account__top {
	background: url(../../img/game_header_bg_blue.4e1fc68d.png);
	background-size: cover;
	box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
	border-radius: 0.26667rem;
	padding: 0.26667rem 0.34667rem;
	text-align: center;
	font-size: 0.34667rem;
	margin-top: 0.33rem;
	height: 220px;
}
.account__top .account__heading {
	color: var(--color-text);
	font-size: 15px;
	font-weight: 600;
	line-height: 30px;
	margin-bottom: 15px;
	padding-top: 15px;
	text-align: center;
	text-transform: capitalize;
}
.account__top .account__ID {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	color: var(--color-text);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}
.account__top .account__ID span {
	color: var(--color-text);
	margin: 0 5px;
	font-size: 0.42rem;
}
.account__top .account__balance {
	display: flex;
	align-items: center;
	padding: 20px 15px 40px;
}
.account__top .account__balance .avatar {
	padding: 0 15px 0 20px;
	width: 35%
}
.account__top .account__balance .name {
	width: 65%;
	text-align: left;
	padding-left: 3%;
}
.account__top .account__balance .name h2 {
	color: #fff;
	font-size: .55rem;
	font-weight: 700;
}
.account__top .account__balance img {
	width: 80px;
	height: 80px;
	border-radius: 100%;
}
.account__top .account__balance span {
	color: #f7f6f6;
	display: block;
	font-size: 13px;
	font-weight: 400;
	margin: 30px 0 15px;
}
.account__top .account__balance strong {
    font-size: 0.5rem;
    font-weight: 700;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#f9e508, #fb9209);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.account__transaction {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
}
.account__transaction,
.account__transaction .account__transaction-box {
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}
.account__transaction .account__transaction-box {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	background: linear-gradient(180deg, #f9e508 0%, #fb9209 100%);
	-webkit-border-radius: 10px;
	border-radius: 10px;
	color: var(--color-text-light);
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	margin-top: -36px;
	padding: 10px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}
.account__transaction .account__transaction-box .account__transaction-item {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-align-items: center;
	align-items: center;
	cursor: pointer;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: center;
	justify-content: center;
	padding: 0 35px;
}
.account__transaction .account__transaction-box .account__transaction-item svg {
	fill: var(--color-text-light);
	display: block;
	font-size: 22px;
}
.account__transaction
	.account__transaction-box
	.account__transaction-item
	span {
	color: var(--color-text-light);
	display: block;
	font-size: 14px;
	line-height: 15px;
	margin-top: 8px;
	font-weight: 500;
}
.account__transaction .account__transaction-box .account__transaction-line {
	background: var(--color-text-light);
	-webkit-border-radius: 100%;
	border-radius: 100%;
	height: 28px;
	width: 1px;
}
.account__menu {
	padding: 20px 22px 15px;
}
.account__menu .account__menu-item {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	cursor: pointer;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	font-size: 16px;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	padding: 8px 0 13px;
	color: rgba(255, 255, 255, 0.8);
}
.account__menu .account__menu-item i,
.account__menu .account__menu-item span {
	font-size: 17px;
	display: flex;
	align-items: center;
}
.account__menu .account__menu-item span svg {
	margin-right: 10px;
	position: relative;
	top: 6px;
	width: 25px;
}
.account__menu .account__menu-item span img {
	height: 30px;
	margin-right: 10px;
}
.account__menu .account__menu-item:last-child {
	border-bottom: none;
}
.account a {
	display: block;
	text-decoration: none;
}
.account__menu .account__menu-item > svg {
	font-size: 0.6rem !important;
	position: relative;
	fill: rgb(87, 119, 136);
}
.background-vip {
	background-position: center center;	
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position:relative;
	margin: 20px 0 25px!important;
}
.img-vip {
	position: absolute;
	z-index: 0;
	transform: scale(1.5)
}
.img-vip-2 {
	transform: scale(1.7);
	margin-top: -4px;
}
.img-vip-3 {
	transform: scale(1.4);
	margin-top: -1px;
}
.img-vip-4 {
	transform: scale(1.6);
	margin-top: -2px;
}
.img-vip-5 {
	transform: scale(1.7);
	margin-top: -3px;
	margin-left: 2px;
}
.img-vip-6 {
	transform: scale(1.7);
}
.img-vip-7 {
	transform: scale(1.6);
	margin-top: 1.5px;
}
.img-vip-8 {
	transform: scale(1.6);
}
.img-vip-9 {
	transform: scale(3);
	margin-top: 3px;
}
.img-vip-10 {
	transform: scale(2.8);
	margin-top: -15px;
	margin-left: -3px;
}
.lottery-menu {
    margin: 0.5rem -0.2rem 0.5rem -0.2rem;
}
.lottery-menu > div {
    display: flex;
}
.lottery-menu-top > .lottery-item {
    position: relative;
    width: 38.2%;
    color: #fff;
    font-size: 0.4rem;
}
.lottery-menu-top > .lottery-item span {
    position: absolute;
    width: 100%;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
}
.lottery-menu-top > .lottery-item:nth-child(2) {
    width: 30.3%;
}
.lottery-menu-top > .lottery-item:nth-child(3) {
    width: 31.5%;
}
.lottery-menu-mid {
    background-image: url(../../img/m-orange.a36778d3.svg), linear-gradient(180deg, rgba(255, 229, 135, 0.2), rgba(255, 229, 135, 0) 108.52%),
        linear-gradient(182.51deg, #ffba94 -16.37%, #f69e70 31.58%, #ee5659 80.54%);
    background-size: 100%;
    box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.2), inset 0 0.01rem 0.02rem rgba(255, 250, 241, 0.8), inset 0 -0.01rem 0.03rem #fdbfbc;
    border-radius: 0.3rem;
    margin: 0 0.16rem;
}
.lottery-menu-mid > .lottery-item {
    flex: 1;
    position: relative;
    color: #fff;
    font-size: 0.35rem;
}
.lottery-menu-mid > .lottery-item:after {
    content: "";
    border-right: 0.01rem solid hsla(0, 0%, 100%, 0.1);
    position: absolute;
    top: 20%;
    height: 60%;
    right: 0;
}
.lottery-menu-mid > .lottery-item span {
    position: absolute;
    width: 100%;
    bottom: 0.15rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
}
.lottery-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    background: #fff;
    border-radius: 0.3rem;
    overflow: hidden;
    box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.06);
}
.lottery-list .lottery-item {
    width: calc(100% / 3);
    border-left: 0.03rem solid #ebebeb;
    padding: 10px 0;
}
.lottery-list .lottery-item:first-child {
    border: 0;
}
.lottery-list .lottery-item img {
    filter: drop-shadow(0 0 0.5rem rgba(255, 138, 211, 0.5));
    border: 0.05rem solid #fff;
    border-radius: 100%;
    max-width: 1.3rem;
}
.lottery-list .lottery-item span {
    color: #333;
    font-size: 0.3rem;
    width: 100%;
    display: block;
}
.personal-bar {
	display: flex;
	align-items: center;
	gap: 15px;
	font-size: 18px;
	font-weight: bold;
	color: #fff;
	text-align: left;
	padding: 5px;
}
.personal-bar .avatar {
	height: 56px;
	width: 56px;
	border-radius: 100%;
	overflow: hidden;
}
.personal-bar p {
	font-size: 14px;
	font-weight: normal;
	text-align: left;
	color: rgb(125, 161, 180);
	margin-top: 6px;
}
.vip-card {
	background: url(../../static/vip_card_bg.png) center center / 100% 100% no-repeat;
	margin: 0 auto;
	width: 90%;
	height: 60px;
	border-radius: 20px 20px 0 0;
	overflow: hidden;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 7px 10px 5px;
	color: rgb(255, 226, 151);
}